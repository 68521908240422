import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52b3221c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_3 = { class: "fieldset__inner" }
const _hoisted_4 = ["type", "value", "name", "placeholder", "readonly", "maxlength", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "icon-search icon"
}
const _hoisted_6 = {
  key: 1,
  class: "symbols-left"
}
const _hoisted_7 = {
  key: 2,
  class: "validation-text"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'fieldset',
      _ctx.subClassName,
      { _readonly: _ctx.readonly, _search: _ctx.label === 'Поиск' },
    ])
  }, [
    _createElementVNode("label", { for: _ctx.id }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        type: _ctx.type,
        value: _ctx.value,
        name: _ctx.id,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = true)),
        onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = false)),
        readonly: _ctx.readonly,
        maxlength: _ctx.maxLength,
        disabled: _ctx.disabled
      }, null, 40, _hoisted_4),
      (_ctx.label === 'Поиск')
        ? (_openBlock(), _createElementBlock("i", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.maxLength && _ctx.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getSymbolsLeft()), 1))
        : _createCommentVNode("", true),
      (_ctx.validationText && !_ctx.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.validationText), 1))
        : _createCommentVNode("", true),
      (_ctx.validationText && _ctx.value && _ctx.getHTMLIcon().length)
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: "validation-icon",
            innerHTML: _ctx.getHTMLIcon(),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cleaNonValidatedValue && _ctx.cleaNonValidatedValue(...args)))
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}