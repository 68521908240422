<template>
  <div ref="chartLine" />
</template>

<script>
import { LineChart } from "@/use/linechart";

export default {
  name: "LineChart",
  props: {
    data: Array,
    dateFormat: String,
  },
  data() {
    return {
      chart: "",
    };
  },
  created() {
    this.getChart();
  },
  watch: {
    data() {
      this.getChart();
      this.$refs.chartLine.innerHTML = "";
      this.$refs.chartLine.appendChild(this.chart);
    },
  },
  methods: {
    getChart() {
      this.chart = LineChart(this.data, {
        x: (d) => new Date(
          d.updated_at || d.created_at
        ),
        y: (d) => d.high_rate,
        width: 550,
        height: 280,
        color: "#3A58FF",
        strokeWidth: 2,
        dateType: this.dateFormat === "byDate" ? "days" : "hours",
      });
    },
  },
  mounted() {
    this.$refs.chartLine.appendChild(this.chart);
  },
};
</script>
