
import { PropType } from "@vue/runtime-core";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "TextInput",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    value: [String, Number],
    id: String,
    placeholder: String,
    type: {
      type: String,
      default: () => "text",
    },
    error: {
      type: String as PropType<string | null | undefined>,
      default: () => undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    subClassName: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
    validationText: String,
  },
  data() {
    return {
      focused: false,
    };
  },
  emits: ["update:value"],
  methods: {
    getHTMLIcon(): string {
      if (this.validateValue()) {
        return '<span class="validate-icon icon-ok validated"></span>';
      } else {
        return '<span class="validate-icon non-validated"><span></span><span></span></span>';
      }
    },
    getSymbolsLeft(): number {
      return this.value
        ? (this as any).maxLength - (this as any).value?.length
        : (this as any).maxLength;
    },
    validateValue() {
      return this.value
        ? /^(https):\/\/[^ "]+$/.test((this as any).value)
        : false;
    },
    clearValue() {
      this.$emit("update:value", "");
    },
    cleaNonValidatedValue() {
      if (!this.validateValue()) {
        this.clearValue();
      }
    },
  },
});
