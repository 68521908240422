
import { defineComponent } from "vue";
import AppPage from "@/components/AppPage.vue";
import TextInput from "@/components/TextInput.vue";
import Button from "@/components/Button.vue";
import LineChart from "@/components/LineChart.vue";

import moment from "moment-timezone";
import axios from "axios";

const Exchanges = [
  {
    text: "RUB/USD",
    from_currency: "RUB",
    to_currency: "USD",
  },
  {
    text: "RUB/EUR",
    from_currency: "RUB",
    to_currency: "EUR",
  },
  {
    text: "USD/EUR",
    from_currency: "USD",
    to_currency: "EUR",
  },
]

interface IDataModel {
  loading: boolean;
  date: string | number;
  exchanges_rates_info: Array<ExchangeInfo>;
  low_rate: number;
  high_rate: number;
  currentPeriodTabIndex: number;
  periodList: Array<PeriodTabListItem>;
  currentPeriod: any;
  formatPeriodForLineChart: string;
  errors: any;
  activeToCurrency: string;
  activeFromCurrency: string;
  exchanges: Array<any>;
  columns: Array<Column>;
}

interface Column {
  id: string;
  name: string;
}

interface ExchangeInfo {
  date: string;
  time: string;
  manager_id: number;
  exchange: number;
}

interface PeriodTabListItem {
  period: string;
  name: string;
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default defineComponent({
  components: {
    AppPage,
    TextInput,
    Button,
    LineChart,
  },
  data(): IDataModel {
    return {
      loading: true,
      date: "",
      low_rate: 0,
      high_rate: 0,
      currentPeriodTabIndex: 0,
      periodList: [
        { period: "5d", name: "5дн" },
        { period: "1m", name: "1мес" },
        { period: "5y", name: "5лет" },
        { period: "", name: "макс." },
      ],
      currentPeriod: {
        name: "5d",
        period: [
          moment().subtract(5, "days").toISOString(),
          moment().toISOString(),
        ],
      },
      exchanges_rates_info: [],
      formatPeriodForLineChart: "byDate",
      errors: {} as { [key: string]: string | null },
      activeFromCurrency: "RUB",
      activeToCurrency: "USD",
      exchanges: Exchanges,
      columns: [
        { id: "date", name: "Дата" },
        { id: "time", name: "Время" },
        { id: "manager_id", name: "ID менеджера" },
        { id: "low_rate", name: "Покупка" },
        { id: "high_rate", name: "Продажа" },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    changeActiveCurrency(from_currency: string, to_currency: string) {
      this.activeFromCurrency = this.exchanges.filter((c) => c.from_currency === from_currency)[0].from_currency;
      this.activeToCurrency = this.exchanges.filter((c) => c.to_currency === to_currency)[0].to_currency;
      this.load();
    },
    getFormatDateForLineChart(period: string) {
      this.formatPeriodForLineChart = period === "1d" ? "byHours" : "byDate";
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    formatTime(d: string): string {
      return moment(d).tz(timezone).format("HH:mm");
    },
    setGraphicPeriod(index: number, period: string) {
      this.currentPeriodTabIndex = index;
      switch (period) {
        case "5d":
          this.currentPeriod = {
            name: "5d",
            period: [
              moment().subtract(5, "days").toISOString(),
              moment().toISOString(),
            ],
          };
          break;
        case "1m":
          this.currentPeriod = {
            name: "1m",
            period: [
              moment().subtract(1, "month").toISOString(),
              moment().toISOString(),
            ],
          };
          break;
        case "5y":
          this.currentPeriod = {
            name: "5y",
            period: [
              moment().subtract(5, "year").toISOString(),
              moment().toISOString(),
            ],
          };
          break;
        default:
          this.currentPeriod = {
            name: "max",
            period: [],
          };
      }
      this.getFormatDateForLineChart(period);
      this.load();
    },
    async load() {
      this.loading = true;

      this.getFormatDateForLineChart(this.currentPeriod.name);
      this.exchanges_rates_info = [];
      this.errors = {};

      try {
        const { data } = await axios.get("/api/exchanges", {
          params: {
            period: this.currentPeriod.period,
            from_currency: this.activeFromCurrency,
            to_currency: this.activeToCurrency,
          },
        });
        this.exchanges_rates_info = data;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    getCurrentDate() {
      const dayNaming = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const monthNaming = [
        "января",
        "ферваля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      const date = new Date();
      const day = date.getDay();
      const month = date.getMonth();
      const year = date.getFullYear();
      this.date =
        dayNaming[day] +
        ", " +
        date.getDate() +
        " " +
        monthNaming[month] +
        " " +
        year +
        ", " +
        moment().tz(timezone).format("HH:mm");
    },
    async apply() {
      try {
        await axios.post("/api/exchanges/save-rate", {
          low_rate: this.low_rate,
          high_rate: this.high_rate,
          from_currency: this.activeFromCurrency,
          to_currency: this.activeToCurrency,
        });
        this.low_rate = 0;
        this.high_rate = 0;

        this.currentPeriod = {
          name: "5d",
          period: [
            moment().subtract(5, "days").toISOString(),
            moment().toISOString(),
          ],
        };
        this.load();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
  mounted() {
    this.getCurrentDate();
    setInterval(this.getCurrentDate, 1000);
  },
});
